<template>
  <div class="box-body">
    <table-items :columns="columns"
                 :data_search="data_search"
                 :relations="relations"
                 component="notification-item"
                 :api_resource="'/employee/' + employeeId + '/notifications'">
    </table-items>
  </div>
</template>

<script>
import Vue from "vue";
import TableItems from "@/components/pagination/table-items.vue";

Vue.component("notification-item", (resolve) => {
  return require(["./notification-item"], resolve);
});

export default {
  name: "employee-notifications",

  components: {
    TableItems,
  },

  props: {
    employeeId: {
      type: [String, Number],
      required: true,
    },
    data_search: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    employee: null,

    columns: [
      {
        title: null,
        class: null,
        style: null,
      },
      {
        title: 'Сообщение',
        class: null,
        style: null,
      },
      {
        title: 'Дата',
        class: null,
        style: null,
      },
    ],
    relations: [

    ],
  }),

  async created() {
    try {

    } catch (error) {
      errorHandler(error);
    }
  },
};
</script>

<style scoped>

</style>
